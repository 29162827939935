<template>
    <div class="account-form">
        <h3>Create your account</h3>
        <div class="form-group">
            <label for="">Sign up as</label>
            <div class="role-grid">
                <div class="role">
                    <span>Customer</span>
                    <input type="radio" id="customer" name="role" value="customer" v-model="data.role">
                    <label for="customer">
                        <span></span>
                    </label>
                </div>

                <div class="role">
                    <span>Merchant</span>
                    <input type="radio" id="merchant" name="role" value="merchant" v-model="data.role">
                    <label for="merchant">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="">Full name</label>
            <input type="text" v-model="data.name" class="form-control" placeholder="John doe" />
        </div>

        <div class="form-group">
            <label for="">Email</label>
            <input type="text" v-model="data.email" class="form-control" placeholder="example@gmail.com" />
        </div>
        
        <div class="form-group">
            <label for="">Password</label>
            <input type="password" v-model="data.password" class="form-control" placeholder="**********" />
        </div>

        <div class="form-group">
            <button class="btn btn-main-gradient btn-block" @click="$emit('eventact', {eventType: 'signup'})">Sign up</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    name: 'Register'
}
</script>
<style lang="scss" scoped>
.account-form {
    padding: .6rem 0rem;

    h3 {
        font-weight: 500;
        font-size: 1.5rem;
        color: #444;
        margin-bottom: 1rem;
    }

    button {
        margin-top: 1.5rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .95rem;
    }
}
.form-group {
    margin-bottom: 1rem;

    label:first-child {
        width: 100%;
        display: block;
        font-size: .8rem;
        font-weight: 600;
        margin-bottom: .5rem;
        color: #444;
    }
}

.form-control {
    height: 40px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
    padding: .3rem 1rem;
    outline: none;
    color: #333;
}
.role-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .role {
        display: flex;
        align-items: center !important;

        input[type=radio] {
            display: none;

            & + label span {
                display: inline-block !important;
                height: 15px !important;
                width: 15px !important;
                padding: 0rem !important;
                border: 2px solid #7b2cbf;
                cursor: pointer;
                border-radius: 50%;
            }

            &:checked + label span {
                background: linear-gradient(to right,  #7b2cbf, #c77dff);
                border: 2px solid #f2f1f5;
            }
        }

        span:first-child {
            padding-right: 1rem;
            font-size: .8rem;
            color: #333;
            font-weight: 500;
        }
    }
}

.btn {
    cursor: pointer;
    outline: none;
    padding: .6rem 1.5rem;
    border-radius: 4px;
    height: 40px;
}

.btn-main-gradient{
    background: linear-gradient(to right,  #7b2cbf, #c77dff) !important;
    color: #fff !important;
}

.btn-block {
    width: 100%;
    display: block;
}
</style>