<template>
    <div class="account-wrapper">
        <div>
            <div>
                <div class="account-header">
                    <a href="/">
                        <img :src="SearchLogo" alt="">
                        <span class="ti-bolt">Fastorder.my</span> 
                    </a>
                    <small>Enjoy Awesome Food and Beverages</small>
                </div>

                <div class="account-tabs">
                    <button :class="isActive === 0? 'is-active': ''" @click="isActive = 0">Login</button>
                    <button :class="isActive === 1? 'is-active': ''" @click="isActive = 1">Register</button>
                </div>

                <div class="account-form-wrapper">
                    <component :is="compsArr[isActive]" :data="compsData[isActive]" @eventact="processEvent"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '@/components/layouts/Login'
import Register from '@/components/layouts/Register'
import SearchLogo from '@/assets/SearchLogo.jpg'

export default {
    name: 'SignIn',
    data() {
        return {
            SearchLogo,
            compsArr: [
                Login,
                Register,
            ],
            isActive: 0,
            compsData: [
                {
                    email: '',
                    password: '',
                },
                {
                    name: '',
                    email: '',
                    password: '',
                    role: '',
                }
            ]
        }
    },
    methods: {
        processEvent(payload) {
            if(payload.eventType === 'signin') {
                this.signin()
            }else if(payload.eventType === 'signup') {
                this.signup()
            }
        },

    }
}
</script>
<style lang="scss" scoped>
@import "../components/common/css/global.scss";
@import "../components/common/css/common.scss";

.account-wrapper {
    min-height: 100vh;
    background: #f2f1f5;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;

    .account-header {
        text-align: center !important;

        a {
            width: 100%;
            font-size: 2.8rem;
            text-align: center !important;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                height: 2.5rem;
                margin-right: 0.5rem;
            }

            span {
                color: #ee2d24;
                font-weight: 600;
            }
        }

        small {
            font-size: 1rem;
            color: #444;
            font-weight: 600;
        }
    }

    .account-tabs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 1rem 0rem;

        button {
            background: #fff;
            color: #8390A2;
            text-transform: uppercase;
            border: none;
            padding: .6rem;
            font-size: 1.1rem;
            font-weight: 600;
            border-bottom: 3px solid #efefef;

            &.is-active {
                border-bottom: 3px solid #e2514a;
            }
        }
    }

    .account-form-wrapper {
        width: 400px;

        @media screen and (max-width: $bp-mobile){
            width: 340px;
        }
    }
}

</style>
