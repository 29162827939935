<template>
    <div class="account-form">
        <h3>Sign into your account</h3>
        <div class="form-group">
            <label for="">Email</label>
            <input type="text" v-model="data.email" class="form-control" placeholder="example@gmail.com" />
        </div>

        <div class="form-group">
            <label for="">Password</label>
            <input type="password" v-model="data.password" class="form-control" placeholder="**********" />
        </div>

        <div class="form-group">
            <button class="btn btn-main-gradient btn-block" @click="$emit('eventact', {eventType: 'signin'})">Sign in</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    name: 'Login'
}
</script>
<style lang="scss" scoped>
.account-form {
    padding: .6rem 0rem;

    h3 {
        font-weight: 500;
        font-size: 1.5rem;
        color: #444;
        margin-bottom: 1rem;
    }

    button {
        margin-top: 1.5rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .95rem;
    }
}
.form-group {
    margin-bottom: 1rem;

    label:first-child {
        width: 100%;
        display: block;
        font-size: .8rem;
        font-weight: 600;
        margin-bottom: .5rem;
        color: #444;
    }
}
.form-control {
    height: 40px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
    padding: .3rem 1rem;
    outline: none;
    color: #333;
}
button {
    cursor: pointer;
    outline: none;
}

.btn {
    padding: .6rem 1.5rem;
    border-radius: 4px;
    height: 40px;
}

.btn-main-gradient{
    background: linear-gradient(to right,  #7b2cbf, #c77dff) !important;
    color: #fff !important;
}

.btn-block {
    width: 100%;
    display: block;
}
</style>